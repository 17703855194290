.testpage {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 100%;
  height: 100vh;
  background-color: #00000090;
  inset: 0;
}

.record-head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.record-head h2 {
  font-size: 35px;
  font-weight: 500;
}

h2 {
  color: #fff;
}

h2.symp-heading {
  font-size: 30px;
  padding-top: 50px;
  padding-bottom: 1px;
  padding-left: 0px;
  color: #845bb3;
}

.testpage-modal {
  padding-bottom: 20px;
  width: 50%;
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  /* background-color:#845BB3; */
  border-radius: 10px;
  transition: max-height 0.5s ease-out;
}

.testpage-modal::-webkit-scrollbar{
  display: none;
}

.record-sec {
  position: relative;
  background-color: #845bb3;
  padding: 80px 20px;
  border-radius: 10px;
}

.img-cough img {
  position: absolute;
  top: 0px;
  right: 0px;
}

button.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem; /* Equivalent to p-2 */
  color: white; /* Equivalent to bg-gray-600 */
  border-radius: 50%; /* Makes it rounded */
  transition: background-color 0.2s ease-in-out;
  z-index: 222;
}

.action-btns {
  display: flex;
  gap: 2rem;
  padding: 10px 0px;
}

.auth-true-action {
  display: flex;
  gap: 0.5rem;
}

.reset-btn {
  padding: 10px 20px;
  border: 1px solid red;
  background-color: red;
  color: white;
  border-radius: 20px;
  width: fit-content;
  font-weight: 500;
}

.reset-btn:hover {
  background-color: rgb(199, 4, 4);
  cursor: pointer;
}

.proceed-btn {
  padding: 10px 20px;
  border: 1px solid #fff;
  background-color: #fff;
  color: #845bb3;
  border-radius: 20px;
  width: fit-content;
  font-weight: 500;
}

.proceed-btn:hover {
  background-color: #dddddd;
  border: 1px solid #dddddd;

  color: #845bb3;
  cursor: pointer;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 20px;
  width: fit-content;
  font-weight: 500;
}

.stop-btn {
  background-color: red;
}

.btn:hover {
  background-color: #fff;
  color: #845bb3;
  cursor: pointer;
}

.symptoms-list {
  /* background-color: red; */
  padding: 0px 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: -5%;
}

.symptoms-list ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;
}

.list-content {
  width: fit-content;
  list-style: none;
  margin: 5px 0px;
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #845bb3;
  border-radius: 20px;
  color: #845bb3;
  font-weight: 600;
}

.symptoms-list ul li:hover {
  background-color: #845bb3;
  color: #fff;
  cursor: pointer;
}

.list-content-selected {
  background-color: #845bb3;
  color: #fff;
  cursor: pointer;
}
.submissive-action-btn {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: -3%;
  background-color: #fff;
  padding: 40px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.submissive-btn {
  padding: 10px 20px;
  border: 1px solid #845bb3;
  background-color: #845bb3;
  color: #fff;
  border-radius: 20px;
  width: fit-content;
  font-weight: 500;
}

.submissive-btn:hover {
  background-color: #e7e6f7;
  color: #845bb3;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: red; /* Light gray background */
  border-radius: 10px; /* Rounded corners */
}
